import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin.routes';

// Angular Material Modules
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';

// Ngx-Charts Module
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Components
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SupplierPerformanceComponent } from './supplier-performance/supplier-performance.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AdminDashboardComponent,
    SupplierPerformanceComponent,
    DashboardOverviewComponent,
    ReportsComponent,
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    NgxChartsModule,
    MatTooltipModule
  ]
})
export class AdminModule { }